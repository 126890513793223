import { defaultActions } from './commons';

type MarriageContractSituationKeys =
  | 'heirat'
  | 'immobilienkauf'
  | 'geburt-eines-kindes';

export const MARRIAGE_CONTRACT_COMMONS = {
  service: 'ehevertrag',
  actions: defaultActions,
  learnMoreLink: '/ehevertrag',
  startProcessLink: '/dashboard/ehevertrag',
  media: {
    image:
      'https://storage.gutgeregelt.ch/assets/vorsorge/recommended_sections/situation-mc.webp',
  },
};

export const MARRIAGE_CONTRACT_SITUATION: Record<
  MarriageContractSituationKeys,
  any
> = {
  heirat: {
    content: {
      main: {
        title: 'marriageContractLanding.situations.heirat.content.main.title',
        description:
          'marriageContractLanding.situations.heirat.content.main.description',
      },
      columns: [
        {
          title:
            'marriageContractLanding.situations.heirat.content.columns.0.title',
          description: [
            'marriageContractLanding.situations.heirat.content.columns.0.description.0',
          ],
        },
        {
          title:
            'marriageContractLanding.situations.heirat.content.columns.1.title',
          description: [
            'marriageContractLanding.situations.heirat.content.columns.1.description.0',
            'marriageContractLanding.situations.heirat.content.columns.1.description.1',
            'marriageContractLanding.situations.heirat.content.columns.1.description.2',
          ],
        },
      ],
    },
  },
  immobilienkauf: {
    content: {
      main: {
        title:
          'marriageContractLanding.situations.immobilienkauf.content.main.title',
        description:
          'marriageContractLanding.situations.immobilienkauf.content.main.description',
      },
      columns: [
        {
          title:
            'marriageContractLanding.situations.immobilienkauf.content.columns.0.title',
          description: [
            'marriageContractLanding.situations.immobilienkauf.content.columns.0.description.0',
          ],
        },
        {
          title:
            'marriageContractLanding.situations.immobilienkauf.content.columns.1.title',
          description: [
            'marriageContractLanding.situations.immobilienkauf.content.columns.1.description.0',
            'marriageContractLanding.situations.immobilienkauf.content.columns.1.description.1',
            'marriageContractLanding.situations.immobilienkauf.content.columns.1.description.2',
          ],
        },
      ],
    },
  },
  'geburt-eines-kindes': {
    content: {
      main: {
        title:
          'marriageContractLanding.situations.geburt-eines-kindes.content.main.title',
        description:
          'marriageContractLanding.situations.geburt-eines-kindes.content.main.description',
      },
      columns: [
        {
          title:
            'marriageContractLanding.situations.geburt-eines-kindes.content.columns.0.title',
          description: [
            'marriageContractLanding.situations.geburt-eines-kindes.content.columns.0.description.0',
          ],
        },
        {
          title:
            'marriageContractLanding.situations.geburt-eines-kindes.content.columns.1.title',
          description: [
            'marriageContractLanding.situations.geburt-eines-kindes.content.columns.1.description.0',
            'marriageContractLanding.situations.geburt-eines-kindes.content.columns.1.description.1',
            'marriageContractLanding.situations.geburt-eines-kindes.content.columns.1.description.2',
          ],
        },
      ],
    },
  },
} as const;

export const getMarriageContractSituation = (
  situation: MarriageContractSituationKeys
) => ({
  ...MARRIAGE_CONTRACT_SITUATION[situation],
  ...MARRIAGE_CONTRACT_COMMONS,
});
